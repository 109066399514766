<template>
  <teleport to="body">
    <div v-if="show" @click="tryClose" class="backdrop"></div>
    <transition name="dialog">
      <dialog open v-if="show">
        <header>
          <slot name="header">
            <h4>{{ title }}</h4>
          </slot>
        </header>
        <section>
          <slot></slot>
        </section>
        <menu v-if="!fixed">
          <slot name="actions">
            <button @click="tryClose" class="submit-button">OK</button>
          </slot>
        </menu>
      </dialog>
    </transition>
  </teleport>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    fixed: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["close"],
  methods: {
    tryClose() {
      if (this.fixed) {
        return;
      }
      this.$emit("close");
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/main.scss";
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;
}

dialog {
  
  position: fixed;
  top: 20vh;
  left: 10%;
  width: 80%;
  z-index: 100;
  border-radius: 5px;
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 0;
  margin: 0;
  overflow: hidden;
  background-color: $white;
}

header {
  background-color: $main-colour;
  color: white;
  text-align: center;
  padding: 0.5rem 1rem;
}

header h4 
{

  margin: 0;
}

section {
  padding: 1rem;
  text-align: center;
}

menu {
  display: flex;
  justify-content: flex-end;
  margin: 0;
}

.dialog-enter-from,
.dialog-leave-to {
  opacity: 0;
  transform: scale(0.8);
}

.dialog-enter-active {
  transition: all 0.3s ease-out;
}

.dialog-leave-active {
  transition: all 0.3s ease-in;
}

.dialog-enter-to,
.dialog-leave-from {
  opacity: 1;
  transform: scale(1);
}

@media (min-width: 768px) {
  dialog {
    left: calc(50% - 15rem);
    width: 30rem;
  }
}


.submit-button {
  color: rgb(255, 255, 255) ;
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
  text-decoration: none;
  font-size: 1rem;
  font-weight: bold;
  background-color: $button-colour;
  padding: 0.2rem 2rem;
  border-radius: 5px;
  border-color: $button-colour;
  transition:
    background-color 0.2s,
    transform 0.2s;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}

.submit-button:hover {
  background-color: $button-colour-light;
  transform: translateY(-3px);
  cursor: pointer;
}

p{
  margin-bottom: 0;
  text-indent: 0;
  max-width: 60%;
}
</style>
