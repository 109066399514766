<template>
  <div>
    <h3>Napisz do nas</h3>
    <div :class="isMobile ? 'form-container-mobile' : 'form-container'">
      <div v-if="!isMobile" class="img-container">
        <img src="./../assets/lettuce.jpg">
      </div>
      <form @submit.prevent="submitForm" class="contact-form">
        <div class="form-group">
          <label for="name">Imię:<span class="star">*</span></label>
          <input type="text" id="name" v-model="formData.name" required>
        </div>
        <div class="form-group">
          <label for="lastname">Nazwisko:</label>
          <input type="text" id="lastname" v-model="formData.lastname">
        </div>
        <div class="form-group">
          <label for="company">Firma:</label>
          <input type="text" id="company" v-model="formData.company">
        </div>
        <div class="form-group">
          <label for="phone">Telefon:<span class="star">*</span></label>
          <input type="tel" id="phone" v-model="formData.phone" required>
        </div>
        <div class="form-group">
          <label for="_replyto">E-mail:<span class="star">*</span></label>
          <input type="email" id="_replyto" v-model="formData.email" required>
        </div>
        <div class="form-group">
          <label for="message">Twoja wiadomość:<span class="star">*</span></label>
          <textarea id="message" v-model="formData.message" required></textarea>
        </div>
        <div><span class="star">*</span> pola obowiązkowe</div>
        <div class="form-group form-group-submit">
          <button type="submit" class="submit-button">Wyślij</button>
        </div>
      </form>
  </div>
  </div>
</template>

  
<script>
import axios from 'axios';
export default {
  props: {
    isMobile: Boolean
  },
  data() {
    return {
      formData: {
        name: "",
        lastname: "",
        company: "",
        phone: "",
        email: "",
        message: "",
      },
    };
  },
  methods: {
    validatePhoneNumber(phoneNumber) {
      
      const phonePattern = /^(\+\d{2,3}[\s-]?)?\d{9}$/;

      return phonePattern.test(phoneNumber);
    },
    submitForm() {
      
      if (this.formData.name === "" || this.formData.phone === "" || this.formData.email === "" || this.formData.message === "") {
        this.$emit('validationError', "Proszę wypełnij wszyskie wymaganie pola!");
      }
      else if(!this.validatePhoneNumber(this.formData.phone)){
        this.$emit('validationError', "Nieprawidłowy numer telefonu!");
      }
      else if(this.formData.name.length < 3){
        this.$emit('validationError', "Imię zbyt krótkie!");
      } else {
        this.$globMet.googleEvent("contact-form-submitted", "submit-form", "Wypełniono formularz kontaktowy", "1");
        axios.post('https://formspree.io/f/xqkvjgdy', this.formData)
          .then(response => {
            this.$emit('response', response);
            this.formData.name = "",
            this.formData.lastname = "",
            this.formData.company = "",
            this.formData.phone = "",
            this.formData.email = "",
            this.formData.message = ""

          })
          .catch(error => {
           this.$emit('error', error.message);
           
          });
      }
    },
  },
 
}
</script>

  
<style scoped lang="scss">
@import "@/main.scss";

.form-container{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10rem;
}

.form-container-mobile{
  display: grid;
  grid-template-columns: 1fr;
  padding: 0 5rem ;
}
.contact-form {
  width: 26rem;
  padding-right: 1rem;
  padding-top: 1rem;
  @media (max-width: 768px) {
    width: 30rem;
    margin-left: auto;
    margin-right: auto;
  }

}

.form-group {
  margin: 0.5rem 0;
  
}


label {
  display: block;
  font-weight: bold;
}

input[type="text"],
input[type="email"],
input[type="tel"],
textarea {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  
}

.submit-button {
  margin-right: auto;
  margin-left: auto;
  text-decoration: none;
  font-size: 1.1rem;
  font-weight: bold;
  background-color: $button-colour;
  padding: 0.5rem 1.5rem;
  border-radius: 5px;
  border-color: $button-colour;
  transition:
    background-color 0.2s,
    transform 0.2s;
  width: max-content;
  color: white;
  white-space: nowrap;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}
.form-group-submit {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}

.submit-button {
  margin-top: 2rem;
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
  text-decoration: none;
  font-size: 1.1rem;
  font-weight: bold;
  background-color: $button-colour;
  padding: 0.5rem 1.5rem;
  border-radius: 5px;
  border-color: $button-colour;
  transition:
    background-color 0.2s,
    transform 0.2s;
  width: 9rem;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}

.submit-button:hover {
  background-color: $button-colour-light;
  transform: translateY(-3px);
  cursor: pointer;

}

.img-container{
  text-align: right;
  padding-left: 1rem;
}

img{
  margin-top: 2rem;
  margin-left: 1rem;
  max-width: 30rem;
  border-radius: 5px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  margin-left: auto;
  margin-right: auto;
}

h3 {
  text-align: center;
  margin-bottom: 2rem;
}

.star{
  color: red;
}

</style>