<template>
    <div class="menu">

        <div class="menu-item" @click="scrollToSection('what')">
          <img src="./../assets/icons/plant.svg">
          <div class="description">Co robimy</div>
        </div>
        <div class="menu-item" @click="scrollToSection('benefits')">
          <img src="./../assets/icons/trophy.svg">
          <div class="description">Korzyści</div>
        </div>

        <div class="menu-item" @click="scrollToSection('realisations')">
          <img src="./../assets/icons/handshake.svg">
          <div class="description">Realizacje</div>
        </div>

        <div class="menu-item" @click="scrollToSection('why')">
          <img src="./../assets/icons/question.svg">
          <div class="description">Dlaczego?</div>
        </div>

        <div class="menu-item" @click="scrollToSection('contact')">
          <img src="./../assets/icons/phone.svg">
          <div class="description">Kontakt</div>
        </div>
    </div>
</template>

<script>
export default {
  name: "AppMenu",
  data() {
    return {
      logo: require("@/assets/Leafmatic_logo-03.svg"),
    };
  },

  methods: {
    scrollToSection(section) {
      this.$globMet.googleEvent(section+"-clicked", "menu-button", "Kliknięto w" + section, "1");
      this.$emit("scrollToSection", section);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/main.scss";






.menu{
  border-top: 1px solid $main-colour;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  
}



.menu-item {
  color: $main-colour;
  text-decoration: none;
  font-size: 1rem;
  border-radius: 10px;
  white-space: nowrap;
  cursor: pointer;
  text-align: center;
  padding: .5rem;
  
}

img{
  margin-top: 0.5rem;
  fill: $main-colour;
  max-width: 2rem;
  
}

.description{
  
}



</style>
