<template>
  <div class="realisations">
    <h3>Realizacje:</h3>
    <h4>
      Nowoczesna instalacja czujników klimatycznych w szklarni pomidorowej i ogórkowej
      </h4>
    <div class="table">
  
      <div class="cell">
        <p>W miejsce tradycyjnej, 30-letniej sieci czujników o niskim zagęszczeniu, zainstalowana została nowoczesna infrastruktura z 36 punktami kontrolnymi oraz 2 czujnikami maty uprawowej na 0,5 ha uprawy. Czujniki pozwalają na bieżąco monitorować kluczowe parametry uprawy, co bezpośrednio wpływa na efektywność uprawy i jakość plonów.
        Czujniki kontrolują takie parametry jak:</p>
    <li>poziom gazów CO2, O2</li>
    <li>poziom oświetlenia, lux i IR, długość dnia (przeliczenie naświetlenia wg algorytmu i szerokości geograficznej)</li>
    <li>temperatura</li>
    <li>wilgotność</li>
    <li>EC, pH maty uprawowej</li>
    <li>ciśnienie atmosferyczne</li>
    <li>wilgotności i temperatura w macie uprawowej</li>


      </div>


      <div class="cell">
        <div class="img-container">
          </div>
      </div>


      <div class="cell">
        <div class="img-container2">
        </div>
      </div>


      <div class="cell">

<p>Dane są dostępne w dedykowanej aplikacji, która umożliwia:</p>
    <li>Monitorowanie na żywo parametrów uprawy,</li>
    <li>Dostęp do historycznych parametrów w celu weryfikacji i porównania,</li>
    <li>Możliwość zintegrowania z czujnikami aktualnie podłączonymi w szklarni oraz czujnikami zewnętrznymi (monitorującymi klimat na zewnątrz szklarni)</li>
    <li>Ustawienie granicznych wartości dla konkretnych parametrów – wysyłanie powiadomień użytkownikowi w momencie przekroczenia ustawionych progrów,</li>
    <li>Możliwość korelacji danych z modułem SI – by zapewnić jak najlepszą kontrolę nad parametrami uprawy</li>
<p>Zainstalowany system został w pełni zintegrowany kablowo, zapewniając stabilność połączenia. Huby są podłączone do sieci elektrycznej i internetowej, a czujniki, zarówno klimatyczne, jak i maty, połączone są bezpośrednio z Hub’em.</p>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OurTeam",
  mounted() {
    const options = {
      threshold: 0.5, // Kiedy uznajemy element za widoczny (50% widoczności)
    };

    // Utwórz obiekt Intersection Observer
    const observer = new IntersectionObserver(this.handleIntersection, options);

    // Obserwuj każdy niepowiązany element osobno
    this.observeElement("elementToAnimate1", observer);
    this.observeElement("elementToAnimate2", observer);
    this.observeElement("elementToAnimate3", observer);
    this.observeElement("elementToAnimate4", observer);
    // Dodaj więcej niepowiązanych elementów, jeśli jest taka potrzeba
  },
  methods: {
    observeElement(refName, observer) {
      const elementToAnimate = this.$refs[refName];
      observer.observe(elementToAnimate);
    },
    handleIntersection(entries, observer) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Element jest widoczny na ekranie, dodaj klasę animacji
          entry.target.classList.add("animate");
          // Zatrzymaj obserwację, aby nie uruchamiała się ponownie
          observer.unobserve(entry.target);
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/main.scss";

.img-container{
  overflow: hidden;
  margin-top: 1rem;
  width: 28rem;
  height: 28rem;
  background-image: url('./../assets/foto/FOTO POMIDORY/LM123c.jpg');
  background-size: cover; // Skaluje tło, aby wypełnić kontener
  background-position: left; // Pozwala regulować widoczny obszar
  background-repeat: no-repeat;
  border-radius: 5px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  @media (max-width: 700px) {
    margin-left: auto;
    margin-right: auto;
  }
}

.img-container2{
  overflow: hidden;
  margin-top: 1rem;
  width: 28rem;
  height: 28rem;
  background-image: url('./../assets/app.jpg');
  background-size: cover; // Skaluje tło, aby wypełnić kontener
  background-position: left; // Pozwala regulować widoczny obszar
  background-repeat: no-repeat;
  border-radius: 5px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  @media (max-width: 700px) {
    margin-left: auto;
    margin-right: auto;
  }
}

.realisations {
  padding: 1rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
}
.table {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
  gap: 5rem;
  @media (max-width: 700px) {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
}

h3,h4 {
  text-align: center;
  margin-bottom: 3rem;
}

img {
  max-width: 100%;
  border-radius: 5px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}

p,li {
  margin-left: 1rem;
  margin-right: 1rem;
  text-align: left;
}

p{
  margin-top: 1rem;
}

.description {
  text-align: left;
}

.name {
  margin-top: 1rem;
  font-weight: bold;
  margin-bottom: 1rem;
  text-align: center;
}

.cell {
  text-align: center;
}
</style>
