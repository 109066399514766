<template>
  <div class="interested-container">
    <div class="grid">
    <div>
    <h3>Problem globalny rolnictwa - problemy rolnika i ogrodnika</h3>
    <p>Współczesne rolnictwo stoi przed licznymi problemami:</p>
      <li>Zmiany klimatyczne plony spadną w przeciągu kilku lat o 20% Stale rosną koszty produkcji rolnej – media, środki ochrony roślin</li>
      <li>Rosnący ślad węglowy ze względu na długie łańcuchy dostaw</li>
      <li>Obecnie rolnictwo zużywa już 70% światowych zasobów słodkiej wody</li>
      <li>45% ludzi nie ma dostępu do wystarczającej ilości owoców i warzyw</li>
      <li>
      Rolnicy i ogrodnicy na całym świecie mierzą się z stale rosnącymi kosztami produkcji rolnej 
      -tj. media (woda, prąd, ogrzewanie), środki ochrony roślin
      </li>
    </div>
    <div class="img-container"><img src="./../assets/foto/traktorc.jpg"></div>
    <div class="img-container"><img src="./../assets/foto/backstage/ALIN7801c.jpg"></div>
    <div>
    <h3>Rozwiązanie Leafmatic</h3>
    <p>Uprawy w kontrolowanym środowisku (szklarnie, hydroponika) </p>
      <li>Redukcja ilości wody niezbędnej do upraw, aż o 90-95% (20-krotnie mniej!)</li>
      <li>15-krotnie większe plony z tego samego areału w porównaniu z rolnictwem tradycyjnym</li>
      <li>Uprawa bliżej ludzi i zminimalizowanie śladu węglowego</li>
      <li>Ograniczenie użycia pestycydów</li>
      <li> Plony cały rok, bez względu na klimat</li>

      <p>W Leafmatic wierzymy, że rolnictwo w kontrolowanym środowisku jest odpowiedzią na zmiany klimatyczne oraz stale rosnące koszty produkcji rolnej. Dlatego rozwijamy pionierskie technologie, oparte o automatyzację i sztuczną inteligencję, które dadzą rolnikom narzędzia do optymalizacji upraw, a konsumentom powszechny dostęp do zdrowszych owoców i warzyw. Rozwiązania, które będą stanowiły o przyszłości gospodarki rolnej i bezpieczeństwa żywnościowego całego Świata.
      </p>
    </div>
    
  </div>
  </div>
</template>

<script>
export default {
  name: "AreYouInterestedInMesurement",
  methods: {
    scrollToSection(section) {
      this.$globMet.googleEvent(section+"-clicked", "content-button", "Kliknięto w ZAMÓW", "1");
      this.$emit("scrollToSection", section);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/main.scss";



.interested-container {
  width: 100%;
  background-color: $main-colour;
  color: #fff;
  margin-right: auto;
  margin-left: auto;
  padding: 1rem;
  padding-top: 2rem;
  padding-bottom: 1rem;
}

.grid{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5rem;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  @media (max-width: 700px) {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
}

p,li {
  
}

.img-container{
  @media (max-width: 700px) {
    margin-left: auto;
    margin-right: auto;
  }
}
img{
  margin-top: 1rem;
  width: 28rem;
  height: 28rem;
  object-fit: cover;
  border-radius: 5px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);


}

h3{
  text-align: left;
}

.order {
  margin-top: 2rem;
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
  text-decoration: none;
  font-size: 1.1rem;
  font-weight: bold;
  background-color: $button-colour;
  padding: 0.5rem 1.5rem;
  border-radius: 5px;
  border-color: $button-colour;
  transition:
    background-color 0.2s,
    transform 0.2s;
  width: 9rem;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}
.order a {
  text-decoration: none;
  color: #fff;
  font-size: 1.1rem;
  font-weight: bold;
}

.order:hover {
  background-color: $button-colour-light;
  transform: translateY(-3px);
  cursor: pointer;
}
</style>
