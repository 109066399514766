<template>
  <div class="hero">
    <img v-if="isMobile" :src="this.logo" alt="Logo Firmy" />
    <h1>Inteligentnie zarządzaj swoją uprawą</h1>
    <h5>
      System do monitorowania i zdalnego sterowania w rolnictwie i ogrodnictwie.
      24 h na dobę,<br />
      7 dni w tygodniu.
    </h5>

    <div class="more-info" @click="scrollToSection('what')">
      DOWIEDZ SIĘ WIĘCEJ
    </div>
  </div>
</template>

<script>
export default {
  name: "AppHero",
  data() {
    return {
      logo: require("@/assets/Leafmatic_logo-03.svg"),
    };
  },
  props: {
    isMobile: Boolean
  },
  methods: {
    scrollToSection(section) {
      this.$globMet.googleEvent(section+"-clicked", "content-button", "Kliknięto w DOWIEDZ SIĘ WIĘCEJ", "1");
      this.$emit("scrollToSection", section);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/main.scss";

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.hero {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  animation: slideInFromLeft 0.75s ease-out;
  
}

h1 {
  margin-top: 10rem;
  max-width: 40%;
  color: white;
  margin-left: 1rem;
}

h5 {
  max-width: 40%;
  color: white;
  margin-left: 1rem;
}


@media (max-width: 768px) {
  h1 {
    max-width: 80%;
    margin-top: 1rem;
  }
  h5 {
    max-width: 80%;
  }
}

.more-info {
  text-decoration: none;
  font-size: 1.1rem;
  font-weight: bold;
  background-color: $button-colour;
  padding: 0.5rem 1.5rem;
  border-radius: 5px;
  border-color: $button-colour;
  transition:
    background-color 0.2s,
    transform 0.2s;
  width: max-content;
  margin-left: 1rem;
  color: white;
  white-space: nowrap;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}
.more-info a {
  text-decoration: none;
  color: #fff;
  font-size: 1.1rem;
  font-weight: bold;
  
}

.more-info:hover {
  background-color: $button-colour-light;
  transform: translateY(-3px);
  cursor: pointer;
}

img{
  max-width: 12rem;
}
</style>
