<template>
  <div class="benefit-container">
    <h3 ref="elementToAnimate1" class="animated-element">Korzyści</h3>
    <div class="benefits">
      <div class="cell">
        <img src="./../assets/icons/002-schedule.svg" />
        <p>Monitoring parametrów uprawy 24/7</p>
      </div>
      <div class="cell">
        <img src="./../assets/icons/001-research.svg" />
        <p>Wgląd w stan uprawy z dowolnego miejsca</p>
      </div>
      <div class="cell">
        <img src="./../assets/icons/003-save-water.svg" />
        <p>Mniejsze zużycie wody</p>
      </div>
      <div class="cell">
        <img src="./../assets/icons/004-combine-harvester.svg" />
        <p>Szybsze zbiory</p>
      </div>
      <div class="cell">
        <img src="./../assets/icons/005-technology.svg" />
        <p>Automatyzacja prac</p>
      </div>
      <div class="cell">
        <img src="./../assets/icons/006-petri-dish.svg" />
        <p>Wykrywanie i zwalczanie patogenów</p>
      </div>

      <div class="cell">
        <img src="./../assets/icons/007-pesticide.svg" />
        <p>Redukcja użycia środków ochrony roślin</p>
      </div>
      <div class="cell">
        <img src="./../assets/icons/008-wheat.svg" />
        <p>Maksymalna wydajność z areału</p>
      </div>
      <div class="cell">
        <img src="./../assets/icons/009-co2-cloud.svg" />
        <p>Zredukowany ślad węglowy</p>
      </div>
      <div class="cell">
        <img src="./../assets/icons/010-happiness.svg" />
        <p>Spokojna głowa- mniej stresu</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppBenefits",
  mounted() {
    const options = {
      threshold: 0.5, // Kiedy uznajemy element za widoczny (50% widoczności)
    };

    // Utwórz obiekt Intersection Observer
    const observer = new IntersectionObserver(this.handleIntersection, options);

    // Obserwuj każdy niepowiązany element osobno
    this.observeElement("elementToAnimate1", observer);

    // Dodaj więcej niepowiązanych elementów, jeśli jest taka potrzeba
  },
  methods: {
    observeElement(refName, observer) {
      const elementToAnimate = this.$refs[refName];
      observer.observe(elementToAnimate);
    },
    handleIntersection(entries, observer) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Element jest widoczny na ekranie, dodaj klasę animacji
          entry.target.classList.add("animate");
          // Zatrzymaj obserwację, aby nie uruchamiała się ponownie
          observer.unobserve(entry.target);
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/main.scss";
.benefits {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

  margin-right: auto;
  margin-left: auto;
  padding: 3rem;
  gap: 2rem;

  @media (max-width: 550px) {
    grid-template-columns: 1fr 1fr;
  }
}

.benefit-container {
  background-color: $gray-colour-light;
  padding-top: 3rem;
}

.cell {
  text-align: center;
}

img {
  max-width: 4rem;
  max-height: 4rem;
}

P {
  text-indent: 0rem;
  font-weight: bold;
}

h3 {
  text-align: center;
}
</style>
