<template>
  <div class="footer">
    <div ref="elementToAnimate1" class="animated-element">
      <div class="first-cell">
        <img src="./../assets/Leafmatic_logo-01.svg" />
        <p>Inteligentnie zarządzaj swoją uprawą</p>
      </div>
    </div>
    <div ref="elementToAnimate2" class="animated-element">
      <div class="cell">
        <h5>leafmatic</h5>
        <ul>
          <li class="active" @click="scrollToSection('teamSection')">O nas</li>
          <li class="active" @click="scrollToSection('what')">Co robimy</li>
          <li class="active" @click="scrollToSection('benefits')">Korzyści</li>
        </ul>
      </div>
    </div>
    <div ref="elementToAnimate3" class="animated-element">
      <div class="cell">
        <h5>Kontakt</h5>
        <ul>
          <li>
            Szczepanów 24,<br />
            58-123 Szczepanów gm. <br />Marcinowice
          </li>
          <li>
            <a href="mailto:m.kowalewska@leafmatic.pl">m.kowalewska@leafmatic.pl</a
            ><br />
            <a href="http://www.leafmatic.pl">leafmatic.pl</a>
          </li>
          <li>+48 889 957 565</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppFooter",

  mounted() {
    const options = {
      threshold: 0.5, // Kiedy uznajemy element za widoczny (50% widoczności)
    };

    // Utwórz obiekt Intersection Observer
    const observer = new IntersectionObserver(this.handleIntersection, options);

    // Obserwuj każdy niepowiązany element osobno
    this.observeElement("elementToAnimate1", observer);
    this.observeElement("elementToAnimate2", observer);
    this.observeElement("elementToAnimate3", observer);

    // Dodaj więcej niepowiązanych elementów, jeśli jest taka potrzeba
  },
  methods: {
    scrollToSection(section) {
      this.$emit("scrollToSection", section);
    },
    observeElement(refName, observer) {
      const elementToAnimate = this.$refs[refName];
      observer.observe(elementToAnimate);
    },
    handleIntersection(entries, observer) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Element jest widoczny na ekranie, dodaj klasę animacji
          entry.target.classList.add("animate");
          // Zatrzymaj obserwację, aby nie uruchamiała się ponownie
          observer.unobserve(entry.target);
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/main.scss";
.footer {
  background-image: url("./../assets/footer-bg.svg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;

  margin-right: auto;
  margin-left: auto;
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr;
  color: #39394a;
}

.cell {
  text-align: left;
  padding: 2rem;
  padding-top: 3rem;
  animation: slideInFromBottom 0.75s ease-out;
}

.first-cell {
  text-align: center;
  padding: 2rem;
}

ul {
  list-style: none;
  text-align: left;
}

li {
  margin-top: 1rem;
}

a {
  text-decoration: none;
  color: inherit;
}

img {
  max-width: 17rem;
}

.active {
  cursor: pointer;
}
p{
  text-indent: 0rem;
}
</style>
