<template>
  <div class="menu-container">
    <header class="menu">
      <div class="logo">
        <img :src="this.logo" alt="Logo Firmy" />
      </div>
      <nav class="menu-items">
        <ul>
          <div class="menu-item" @click="scrollToSection('what')">Co robimy</div>
          <div class="menu-item" @click="scrollToSection('benefits')">
            Korzyści
          </div>
          <div class="menu-item" @click="scrollToSection('why')">
            Dlaczego?
          </div>

          <div class="menu-item" @click="scrollToSection('realisations')">
            Realizacje
          </div>



          
          <div class="contact" @click="scrollToSection('contact')">Kontakt</div>
        </ul>
      </nav>
    </header>
  </div>
</template>

<script>
export default {
  name: "AppMenu",
  data() {
    return {
      logo: require("@/assets/Leafmatic_logo-03.svg"),
    };
  },

  methods: {
    scrollToSection(section) {
      this.$globMet.googleEvent(section+"-clicked", "menu-button", "Kliknięto w" + section, "1");
      this.$emit("scrollToSection", section);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/main.scss";
.menu-container{
  background-color: rgba(0,0,0,0.3);
}
.menu {
  display: flex;
  justify-content: space-between;
  color: #fff;

  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
}

.logo img {
  margin-top: -0.5rem;
  margin-bottom: -1rem;
  width: 8rem;
}

.menu-items {
  height: 100%;
}

.menu-items ul {
  list-style: none;
  display: flex;
  gap: 20px;
  margin-right: 3rem;
  height: 100%;
  margin-top: 0.5rem;
}

.menu-items div a {
  text-decoration: none;
  color: #fff;
  font-size: 1.1rem;
  font-weight: bold;
}

.menu-item {
  text-decoration: none;
  font-size: 1.1rem;
  font-weight: bold;
  padding: 0.5rem 0.5rem;
  border-radius: 10px;
  white-space: nowrap;
  cursor: pointer;
}

.contact {
  text-decoration: none;

  font-size: 1.1rem;
  font-weight: bold;
  background-color: $button-colour;
  padding: 0.5rem 1.5rem;
  border-radius: 5px;
  border-color: $button-colour;
  transition:
    background-color 0.2s,
    transform 0.2s;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}

.contact:hover {
  background-color: $button-colour-light;
  transform: translateY(-3px);
  cursor: pointer;
}

.menu-items li a:hover {
}
</style>
