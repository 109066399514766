<template>
  <div class="doing">
    <h3>
      CO ROBIMY?
    </h3>
    <div class="table">
      <div class="foto">
        
        
      </div>
      <div >
        <p ref="elementToAnimate1" class="animate-on-scroll">
          Leafmatic powstał z miłości do rolnictwa i nowych technologii. Sami od podstaw stworzyliśmy uprawę kontenerową hydroponiczną w systemie wertykalnym, w której stale testujemy nasze innowacyjne rozwiązania dla upraw kontenerowych i pod osłonami. Tworzymy czujniki środowiska, kontrolery i aplikacje zarządzającą całą uprawą. Z kolei działające już w gospodarstwach rolnych i ogrodniczych instalacje i czujniki integrujemy w jeden, prosty w obsłudze system. 
            </p>
            <p ref="elementToAnimate2" class="animate-on-scroll">
             
              <ul>
                <li>
                maksymalizacja plonów,  
                </li>
                <li>
                  redukcja niezbędne do produkcji zasoby (czas, energia, woda, nawozy)  
                </li>
                <li>
                  identyfikacja i zapobieganie problemom, zanim się pojawią.
                </li>
              </ul>
          
           </p>
            <p ref="elementToAnimate3" class="animate-on-scroll">
            Już dzisiaj możemy pomóc Ci zaprząc technologię do pracy przy Twojej uprawie i uczynić rolnictwo bardziej wydajnym i opłacalnym, a jednocześnie prostszym i łatwiejszym.  
        </p>
        <div class="contact-button" @click="scrollToSection('contact')">
          SKONTAKTUJ SIĘ Z NAMI
        </div>
      </div> 
      
    </div>
  </div>
</template>

<script>
export default {
  name: 'WhatWeAreDoing',
  mounted() {
    const options = {
      threshold: 0.5, // Kiedy uznajemy element za widoczny (50% widoczności)
    };

    // Utwórz obiekt Intersection Observer
    const observer = new IntersectionObserver(this.handleIntersection, options);

    // Obserwuj każdy niepowiązany element osobno
    this.observeElement('elementToAnimate1', observer);
    this.observeElement('elementToAnimate2', observer);
    this.observeElement('elementToAnimate3', observer);
    // Dodaj więcej niepowiązanych elementów, jeśli jest taka potrzeba
  },
  methods: {
    scrollToSection(section) {
      this.$globMet.googleEvent(section+"-clicked", "content-button", "Kliknięto w SKONTAKTUJ SIĘ Z NAMI", "1");
      this.$emit("scrollToSection", section);
    },
    observeElement(refName, observer) {
      const elementToAnimate = this.$refs[refName];
      observer.observe(elementToAnimate);
    },
    handleIntersection(entries, observer) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // Element jest widoczny na ekranie, dodaj klasę animacji
          entry.target.classList.add('animate');
          // Zatrzymaj obserwację, aby nie uruchamiała się ponownie
          observer.unobserve(entry.target);
        }
      });
    },
  },

}
</script>


<style scoped lang="scss">

@import "@/main.scss";

.foto{
  overflow: hidden;
  margin-top: 1rem;
  width: 28rem;
  height: 28rem;
  background-image: url('./../assets/foto/backstage/ALIN7853c.jpg');
  background-size: cover; // Skaluje tło, aby wypełnić kontener
  background-position: center; // Pozwala regulować widoczny obszar
  background-repeat: no-repeat;
  border-radius: 5px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  @media (max-width: 700px) {
    margin-left: auto;
    margin-right: auto;
  }
}
.doing{
  padding: 1rem;
}

.table{
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
  gap: 5rem;
  @media (max-width: 700px) {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
}

h3{
  text-align: center;
  margin-bottom: 3rem;
}

img{
  margin-top: 1rem;
  max-width: 100%;
  border-radius: 5px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}

.animate-on-scroll {
  
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.animate {
  opacity: 1;
  transform: translateY(0);
}

p{
  margin-right: 1rem;
  margin-top: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

li{
  margin-top: 0.5rem;
  text-indent: 0rem;
}

.contact-button {
  
  text-decoration: none;
  font-size: 1.1rem;
  font-weight: bold;
  background-color: $button-colour;
  padding: 0.5rem 1.5rem;
  width: max-content;
  border-radius: 5px;
  border-color: $button-colour;
  transition:
    background-color 0.2s,
    transform 0.2s;
  margin-left: auto;
  margin-right: auto;
  color: white;
  white-space: nowrap;
  margin-bottom: 1rem;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}
.contact-button a {
  text-decoration: none;
  color: #fff;
  font-size: 1.1rem;
  font-weight: bold;
}

.contact-button:hover {
  background-color: $button-colour-light;
  transform: translateY(-3px);
  cursor: pointer;
}

</style>
